@import "../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  height: calc(74vh - 1rem);
  background-color: $bg-white;
  border-radius: 2rem;
  @media (min-width: 200px) and (max-width: 480px) {
    height: calc(100vh - 5rem);
    border-radius: 0rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    height: calc(100vh - 5rem);
    border-radius: 0rem;
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
  }
  @media (min-width: 1401px) {
  }
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2rem 1.5rem 2rem;
    .title {
      color: $secondary;
      font-size: $title4;
      font-weight: bold;
    }
    >Button {
      padding: 1rem;
      @media (min-width: 350px) and (max-width: 480px) {
        position: absolute;
        bottom: 6rem;
        right: 2rem;
      }
      @media (min-width: 481px) and (max-width: 768px) {
        position: absolute;
        bottom: 6rem;
        right: 2rem;
      }
      @media (min-width: 769px) and (max-width: 992px) {
      }
      @media (min-width: 993px) and (max-width: 1200px) {
      }
      @media (min-width: 1201px) and (max-width: 1400px) {
      }
      @media (min-width: 1401px) {
      }
    }
  }
  .message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: $title5;
    font-weight: bold;
    color: $secondary;
    padding: 3rem;
  }
  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: $bg-white;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    column-gap: $gap-base;
    row-gap: $gap-base;
    scrollbar-width: none;
    @media (min-width: 200px) and (max-width: 480px) {
      max-width: 100%;
      flex-direction: column;
      flex-wrap: nowrap;
    }
    @media (min-width: 481px) and (max-width: 768px) {
      max-width: 100%;
      flex-direction: column;
      flex-wrap: nowrap;
    }
    @media (min-width: 769px) and (max-width: 992px) {
      max-width: 100%;
      flex-direction: column;
      flex-wrap: nowrap;
    }
    @media (min-width: 993px) and (max-width: 1200px) {
      max-width: 100%;
      flex-direction: column;
      flex-wrap: nowrap;
    }
    @media (min-width: 1201px) and (max-width: 1400px) {
      max-width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
    }
    @media (min-width: 1401px) {
    }
  }
}
