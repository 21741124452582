@import "../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100%;
  gap: $gap-small;
  .nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
    .headerLink {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; 
      width: 100%;
      .link {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: $border-radius;
        gap: $gap-small;
        border: 2px solid transparent;
        cursor: pointer;
        text-wrap: nowrap;
        .item {
          font-size: $font-size-base;
          font-weight: 700;
          color: $primary;
        }
      }
      .link:hover {
        border: 2px solid $primary;
      }
  
      .link_active {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: $border-radius;
        gap: $gap-small;
        cursor: pointer;
        background-color: $primary;
        .item {
          font-size: $font-size-base;
          font-weight: bold;
          color: $white;
        }
      }
      .disable {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 0.5rem 1rem 0.5rem 1rem;
        border-radius: $border-radius;
        gap: $gap-small;
        cursor: default;
        .item {
          font-size: $font-size-base;
          font-weight: bold;
          color: $disable;
        }
      }
    }
  }

  .footerNav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 0.5rem;
    .profile {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      .account {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius:  2rem 0rem 0rem 2rem;  
        background-color: $bg-white; 
        width: 100%;
        .link {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 0.5rem 0.56rem 0.5rem 0.56rem;
          width: 100%;
          border-radius: 2rem;      
          cursor: pointer;
          .avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            padding: 1.2rem;
            .image {
              border-radius: 50%;
              width: 2.4rem;
              height: 2.4rem;
              cursor: pointer;
            }
          }
          .item {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: $gap-base;
            font-size: $font-size-base;
            font-weight: bold;
            width: 100%;
            color: $secondary;
          }
        }
      
        
        .link_active {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 0.5rem 0.56rem 0.5rem 0.56rem;
          width: 100%;
          border-radius: 2rem;  
          cursor: pointer;
          background-color: $secondary;
          color: $white;
          border-radius: 2rem;
          .avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            padding: 1.2rem;
            .image {
              border-radius: 50%;
              width: 2.4rem;
              height: 2.4rem;
              cursor: pointer;
            }
          }
          .item {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: $gap-base;
            font-weight: bold;
            width: 100%;
            color: $white;
          }
        }
        .disable {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0.25rem 1rem 0.25rem 1rem;
          width: 100%;
          border-radius: 2rem;
          :hover{
            cursor:default;
          }
          .picto {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 3rem;
            height: 3rem;
            border-radius: $border-radius;
            .icon {
              fill: $gray;
              width: 1.5rem;
              height: 1.5rem;
            }
          }
          .item {
            display: flex;
            padding-left: 0.5rem;
            font-size: $title4;
            font-weight: bold;
            width: 100%;
            color: $gray;
          }
        } 
      }
      .disconnect {
        padding: 0.5rem  0.5rem 0.5rem 0rem ;
        background-color: $bg-white;
        border-radius: 0rem 2rem 2rem 0rem;
        .btn {
          padding: 0.45rem;
          border-radius: 2rem;
          &:hover {
            background-color: $danger-transparent;
          }
        }
      }
    }
    .helper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .link {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        font-size: $font-size-small;
        font-weight: 700;
        line-height: 120%;
        color: $primary;
      }
    }
  }
}










