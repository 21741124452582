html {
  font-size: 16px; /* Default font-size global 16px = 1rem */
  font-weight: 100;
  overflow: hidden;
}

body {
  overflow: hidden;
  color: $black;
  background: radial-gradient(68.88% 68.88% at 5.57% 15.59%, #F6FBFF 0%, #AECDF0 100%);
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

body > div {
  opacity: 1;
}

html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $secondary;
  font-weight: bold;
  margin: 0;
}

h1 {
  font-size: $title1;
}
h2 {
  font-size: $title2;
}
h3 {
  font-size: $title4;
}
h4 {
  font-size: $title4;
}
h5 {
  font-size: $title4;
}
h6 {
  font-size: $font-size-base;
}

a,
.dropdown-item,
.btn {
  cursor: pointer;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out,
  border-color 0.1s ease-in-out;
  font-family: $font-family-base;
  text-decoration: none;
}

a:hover {
  color: $bg-white;
  text-decoration: none;
}

th {
  font-weight: 600;
}

td {
  font-weight: 300;
}

label {
  font-weight: 400;
}

strong {
  font-weight: 700;
}

ul ul,
ol ul {
  list-style: none;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}