@import "../../styles/app";

.root {
  @extend %page-base;
  .content {
    @extend %page-content;
    display: flex;
    align-items: center;
  }
}



