@import "../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 1rem 0rem;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $gap-base;
    padding-top: 1rem;
    .account {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .item {
        display: flex;
        flex-direction: row;
        gap: $gap-small;
        .label {
          color: $secondary;
        }
        .status {
          color: $secondary;
        }
      }
      .buttonsNetatmo {
        .btn {
          background-color: $bg-white;
          border: 2px solid $primary;
          color: $primary;
        }
      }
      .buttonsSmarthings {
        .btn {
          background-color: $bg-white;
          border: 2px solid $primary;
          color: $primary;
        }
      }
    }
  }
}
