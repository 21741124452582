@import "../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  .content {
    display: flex;
    flex-direction: row;
    margin-bottom: 0rem;
    width: 100%;
    height: 75%;
    .cardInfo {
      display: flex;
      flex-direction: column;
      margin-bottom: 0rem;
      width: 100%;
      justify-content: center;
      gap: $gap-base;
      > li {
        display: flex;
        flex-direction: row;
        gap: $gap-base;
        font-size: $font-size-base;
        justify-content: flex-start;
        align-items: center;
        > span:first-child {
          display: flex;
          flex: 1;
          color: $secondary;
        }
        > span:last-child {
          display: flex;
          color: $secondary;
        }
        .themeBall{
          width: 2rem;
          height: 2rem;
          background-color: $primary;
          border: 1px solid $primary;
          background-image: -webkit-linear-gradient(135deg, $primary 51%, $bg-white 49%);
          border-radius: 2rem;
        }
      }
    }
  }
}
