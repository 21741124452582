@import '../../../../styles/app.scss';

.root {
  display: flex;
  flex-direction: column;
  gap: $gap-high;
  width: 30em;
  @media (min-width: 350px) and (max-width: 480px) {
    width: 100%;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 100%;
  }
  .subscription {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .title {
      font-size: $title2;
      font-weight: 100;
      color: $secondary;
    }
  }
  
}
