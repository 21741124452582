@import "../../../styles/app";

.root {
  // border: 1px dashed red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: -webkit-fill-available;
  gap: 1.5rem;
  overflow-y: auto;
  scrollbar-width: none;
  @include respond-up(lg) {
    // border: 1px dashed red;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 9rem;
  }
  .logo {
    // border: 1px dashed black;
    display: none;
    @include respond-up(sm) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .img {
      // border: 1px dashed red;
      width: 20rem;
      fill: $primary;
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    // min-height: 100vh;
    @include respond-up(sm) { width: 60%; }
    @include respond-up(md) { width: 50%; }
    @include respond-up(lg) { width: 35%; }
    @include respond-up(xl) { width: 30%; }
    @include respond-up(xxl) { width: 25%; }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background-color: $bg-white;
      border-radius: 0rem;
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
      width: 100%;
      min-height: 100vh;
      gap: $gap-high;
      @include respond-up(sm) {
        gap: $gap-base;
        border-radius: $border-radius;
        padding: 3rem 1.5rem 3rem 1.5rem;
        min-height: 100%;
      }
      header {
        font-size: $title1;
        font-weight: 300;
        line-height: 120%;
      }

      .form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: $font-size-small;
        .formGroup {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .error_message {
          background-color: transparent;
          color: $danger;
          font-weight: bold;
          padding: 0;
          border-radius: 0;
        }
        .control {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          @include respond-up(sm) {
            padding-top: 0.5rem;
          }
        }
      }

      .separator {
        display: flex;
        align-items: center;
        padding-top: 3rem;
        padding-bottom: 3rem;
        width: 100%; 
        > span {
          border-top: 2px solid $primary;
          width: 100%
        }
      }

      footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding: 0rem;
        gap: $gap-high;
        width: 100%;
        .label {
          font-size: $title3;
          line-height: 120%;
          text-align: center;
        }
      }
    }
  }
}




