@import '../../../../styles/app.scss';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $bg-white;
  width: 100%;
  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: $title3;
    font-weight: bold;
    color: $secondary;
  }
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: $gap-base;
  height: 100%;
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1rem;
    gap: $gap-high;
    .identity {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: $gap-base;
    }
    .formGroup {
      display: flex;
      flex-direction: column;
      margin-bottom: 0rem !important;
      width: 100%;
      .label {
        color: $secondary;
        font-weight: bold;
        margin-bottom: 0rem !important;
      }
      .input {
        width: 100%;
        background-color: $light-gray;
      }
    }
  }
  .confirm {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;
    .btn {
      display: flex;
      flex-direction: row;
      background-color: $primary;
      border: 2px solid $primary;
      color: $bg-white;
      cursor: pointer;
      width: fit-content;
    }
  }
}
