@import "../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 1rem 0rem;
  height: 100%;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px outset $white;
    .title {
      color: $secondary;
      font-size: $title3;
      font-weight: bold;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $gap-base;
    height: 100%;
    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 1rem;
      gap: $gap-high;
      .identity {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: $gap-base;
      }
      .formGroup {
        display: flex;
        flex-direction: column;
        margin-bottom: 0rem !important;
        width: 100%;
        .label {
          color: $secondary;
          font-weight: bold;
          margin-bottom: 0rem !important;
        }
        .input {
          width: 100%;
          background-color: $light-gray;
        }
      }
    }
    .modify {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 1rem;
      .linkUpdate {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        background-color: $bg-white;
        border: 2px solid $primary;
        color: $primary;
        cursor: pointer;
        width: fit-content;
      }
    }
  }
}
