@import '../../../../styles/app.scss';

.root {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 30em;
  @media (min-width: 481px) and (max-width: 768px) {
    height: calc(100vh - 1rem);
    gap: 0rem;
    border-radius: 0rem;
  }
  @media (min-width: 350px) and (max-width: 480px) {
    width: 100%;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 100%;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-self: center;
    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 6.5rem;
      height: 6.5rem;
      border: 2px solid $secondary;
      border-radius: 52px;
      .delete {
        position: absolute;
        top: 1%;
        left: 88%;
        .icon {
          width: 1.8rem;
          height: 1.8rem;
          border-radius: 0.9rem;
          cursor: pointer;
        }
      }
      .image {
        border-radius: 50px;
        width: 100px;
        height: 100px;
        cursor: pointer;
      }
      .overlay_circle {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 4.2rem;
        bottom: 0;
        right: 0;
        width: 3.2rem;
        height: 3.2rem;
        background-color:  $white;
        border: 2px solid $secondary;
        border-radius: 35%;
        cursor: pointer;
        .icon{
          padding-top: 5rem;
        }
      }
    }
  }
  .profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      font-size: $title2;
      font-weight: 100;
      color: $secondary;
    }
  }
  .line {
    background-color: $primary;
    border-radius: 2rem;
    width: 100%;
    height: 1px;
  }
  .preferences {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: flex-start;
    .title {
      font-size: $title2;
      font-weight: 100;
      color: $secondary;
    }
    .notification {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      justify-content: space-between;
      color: $secondary;
      .toggleButton {
        display: flex;
        bottom: 1.5rem;
        cursor: pointer;
        .background {
          display: flex;
          width: 3.5rem;
          padding: 0.5rem;
          align-items: flex-start;
          gap: $gap-small;
          border-radius: 5.3125rem;
          &.background_on {
            background-color: $bg-app;
          }
          &.background_off {
            background-color: $gray-400;
          }
          .label {
            display: flex;
            width: 1rem;
            height: 1rem;
            border-radius: 5.3125rem;
            transition: background-color 0.3s ease,
            transform 0.3s ease;
            cursor: pointer;
            &.on {
              background-color: $primary;
              transform: translateX(150%);
              color: $bg-white;
            }
            &.off {
              background-color: $gray-800;
              transform: translateX(0%);
              color: $bg-white;
            }
          }
        }
      }
    }
  }
}
