@import "../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px outset $white;
    .title {
      color: $secondary;
      font-size: $title3;
    }
    .btn {
      background-color: $primary;
      border-color: $primary;
      border-radius: 0.5rem;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin-bottom: 0rem;
    .cardInfo {
      display: flex;
      flex-direction: column;
      margin-bottom: 0rem;
      width: 100%;
      gap: $gap-base;
      > li {
        display: flex;
        flex-direction: row;
        gap: $gap-base;
        font-size: $font-size-base;
        justify-content: space-between;
        align-items: center;
        .infos {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          > span:first-child {
            display: flex;
            flex: 1;
            font-weight: bold;
            color: $secondary;
          }
          > span:last-child {
            display: flex;
            font-size: $font-size-small;
          }
        }
        .other {
          display: flex;
          flex-direction: row;
          gap: 1rem;
          .role {
            display: flex;
            align-items: center;
          }
          .more {
            background-color: $bg-white;
            border: 1px solid $bg-white;
            padding: 0.5rem;
            border-radius: 2rem;
            &:hover {
              background-color: $bg-app;
              border: 1px solid $bg-app;
              border-radius: 2rem;
              cursor: pointer;
            }
          }
        }
      }
    }
    .control {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      height: 100%;
      padding-top: 1rem;
    }
  }
  .listGroup {
    border: 1px solid $light-gray;
    display: flex;
    position: absolute;
    background-color: $bg-white;
    z-index: 1;
    bottom: 38%;
    right: 14%;
    border-radius: 1.75rem;
    width: 15%;
    .item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: $gap-small;
      padding-left: 1rem;
      padding-right: 1rem;
      width: 100%;
      .name {
        display: flex;
        justify-content: flex-start;
        background-color: transparent;
        border: none;
      }
    }
    .item:hover:first-child {
      border-radius: $border-radius $border-radius 0 0;
    }
    .item:hover:last-child {
      border-radius: 0 0 $border-radius $border-radius;
    }
    .item:hover {
      background-color: $light-gray;
    }
  }
}
