$min-contrast-ratio: 3.5;

// ----------------------------------
// Custom Variable for Insensia V2
// ----------------------------------

//GLOBAL TYPOGRAPHY
$font-family-base: "Roboto", sans-serif, "Open Sans";

$font-weight: 300 !default;

$font-size-base: 1rem !default;
$font-size-small: 0.875rem;

$title1: 2.375rem;
$title2: 1.8rem;
$title3: 1.5rem;
$title4: 1.3rem ;
$title5: 1.2rem;

// COULEUR PRINCIPALE DE L'APPLICATION
$primary: #3192D3; //ok
$secondary: #30465E; //ok
$success: #61D173; //ok
$info: #4179cf; //ok
$warning: #FF8F0B; //ok
$danger: #E6514C; //ok
$disable: #595d78;

//A AJUSTER SELON FIGMA
$lime: #8cbf26 !default;
$red: #ff3c3c !default; //ok
$red-dark: #d04f4f !default;
$blue: #2477ff !default;
$green: #2d8515 !default;
$orange: #ff8f0b !default; //ok
$pink: #e671b8 !default;
$purple: #a700ae !default;
$brown: #a05000 !default;
$teal: #4179cf !default;
$subtle-blue: #474d84 !default;
$gray: #a6aeb9 !default;
$violet_deep: #1b1e3c !default;


$white: #f4f4f5 !default; //ok
$black: #000 !default; //ok
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #d6dee5 !default;
$gray-400: #c1ccd3 !default;
$gray-500: #a3aeb7 !default;
$gray-600: #798892 !default;
$gray-700: #495057 !default;
$gray-800: #3c484f !default;
$gray-900: #29323a !default;

$light-primary: #3192D31A; //ok
$light-green:#98C648; //ok
$light-gray: #d7dade; //ok

$primary-transparent: rgba($primary,0.1); //ok
$danger-transparent: #ff3c3c1f; //ok
$red-transparent: rgba($red,0.1); //ok
$background-modal: #00000080;
$no_values : #34495E80;
$link-color: #1870dc; //ok

$bg-white: #ffffff; //ok
$bg-app:#EAF4FB; //ok
$bg-custom-dark: #040620; //ok

$shadow: 0 25px 20px -20px rgba(0, 0, 0, 0.1), 0 0 15px rgba(0, 0, 0, 0.06); //ok

$border-radius: 1.25rem; //ok
$gap-small:0.5rem;
$gap-base:1rem;
$gap-high:1.5rem;

