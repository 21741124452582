@import '../../../../styles/app.scss';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $gap-base;
  width: 30em;
  @media (min-width: 350px) and (max-width: 480px) {
    width: 100%;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 100%;
  }
  .company {
    .title{
      font-size: $title2;
      font-weight: 100;
      color: $secondary;
    }
    .cardCompany {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  
  .line {
    background-color: $primary;
    border-radius: 2rem;
    width: 100%;
    height: 1px;
  }
  .assocciate {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: flex-start;
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .title {
        display: flex;
        flex-direction: row;
        font-size: $title2;
        color: $secondary;
      }
      .btnAdd {
        background-color: $bg-white;
        border: 2px solid $primary;
        color: $primary;
        cursor: pointer;
      }
    }
    .notification {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      justify-content: space-between;
      .toggleButton {
        display: flex;
        bottom: 1.5rem;
        cursor: pointer;
        .background {
          display: flex;
          width: 3.5rem;
          padding: 0.5rem;
          align-items: flex-start;
          gap: $gap-small;
          border-radius: 5.3125rem;
          &.background_on {
            background-color: $bg-app;
          }
          &.background_off {
            background-color: $gray-400;
          }
          .label {
            display: flex;
            width: 1rem;
            height: 1rem;
            border-radius: 5.3125rem;
            transition: background-color 0.3s ease,
            transform 0.3s ease;
            cursor: pointer;
            &.on {
              background-color: $primary;
              transform: translateX(150%);
              color: $bg-white;
            }
            &.off {
              background-color: $gray-800;
              transform: translateX(0%);
              color: $bg-white;
            }
          }
        }
      }
    }
  }
}