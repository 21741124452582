@import "../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  .content {
    display: flex;
    flex-direction: row;
    margin-bottom: 0rem;
    .cardInfo {
      display: flex;
      flex-direction: column;
      margin-bottom: 0rem;
      width: 100%;
      gap: $gap-base;
      > li {
        display: flex;
        flex-direction: row;
        gap: $gap-base;
        font-size: $font-size-base;
        justify-content: space-between;
        align-items: center;
        > span:first-child {
          display: flex;
          color: $secondary;
        }
        > span:last-child {
          display: flex;
          
        }
      }
    }
  }
}
