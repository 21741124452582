@import '../../../../styles/app.scss';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $bg-white;
  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: $title3;
    font-weight: bold;
    color: $secondary;
  }
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: $gap-base;
  height: 100%;
  .message {
    font-size: $font-size-base;
  }
  .btn {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    background-color: $danger;
    border: 2px solid $danger;
    color: $bg-white;
    cursor: pointer;
    width: fit-content;
  }
}
