@import "../../styles/app";

.img {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  fill: $primary;
  padding: 1.25rem 0 2.75rem 0;
  @media (min-width: 200px) and (max-width: 480px) {
    width: 50%;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 50%;
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
  }
  @media (min-width: 1401px) {
  }
}




