@import '../../../../styles/app.scss';

.root {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 30em;
  @media (min-width: 350px) and (max-width: 480px) {
    width: 100%;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 100%;
  }
  .consent {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .title {
      font-size: $title2;
      font-weight: 100;
      color: $secondary;
    }
    .enedis {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      >span {
        color: $secondary;
        font-weight: 700;
      }
    }
  }
  .line {
    background-color: $primary;
    border-radius: 2rem;
    width: 100%;
    height: 1px;
  }
  .associated {
    .title {
      font-size: $title2;
      font-weight: 100;
      color: $secondary;
    }
  }
}
