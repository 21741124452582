// Extra-small (xs) : jusqu'à 576px
// (Typiquement pour les mobiles en portrait)

// Small (sm) : à partir de 576px
// (Pour les mobiles en mode paysage)

// Medium (md) : à partir de 768px
// (Pour les tablettes)

// Large (lg) : à partir de 992px
// (Pour les ordinateurs portables)

// Extra-large (xl) : à partir de 1200px
// (Pour les grands écrans)

// XXL (optionnel) : à partir de 1400px
// (Pour les très grands écrans)


$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

@mixin respond-up($breakpoint) {
  @if $breakpoint == sm {
    @media (min-width: $breakpoint-sm) { @content; }
  } @else if $breakpoint == md {
    @media (min-width: $breakpoint-md) { @content; }
  } @else if $breakpoint == lg {
    @media (min-width: $breakpoint-lg) { @content; }
  } @else if $breakpoint == xl {
    @media (min-width: $breakpoint-xl) { @content; }
  } @else if $breakpoint == xxl {
    @media (min-width: $breakpoint-xxl) { @content; }
  }
}

@mixin rotateRight {
  animation: scaleRotateRightEffect 2s linear;
}

@mixin rotateLeft {
  animation: scaleRotateLeftEffect 2s linear;
}

@keyframes scaleRotateRightEffect {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1) rotate(180deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}

@keyframes scaleRotateLeftEffect {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1) rotate(-180deg);
  }
  100% {
    transform: scale(1) rotate(-360deg);
  }
}


