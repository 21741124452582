@import "../../../../styles/app";

.root {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.5rem 0.5rem 0 0;
    .title {
      display: flex;
      flex-direction: row;
      font-size: $title4;
      font-weight: bold;
      color: $secondary;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    background-color: $bg-white;
    margin-bottom: 0rem;
    border-radius: 0 0 0.5rem 0.5rem;
    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: $gap-high;
      .formGroup {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem !important;
        width: 100%;
        .label {
          color: $secondary;
          font-weight: bold;
          margin-bottom: 0rem !important;
        }
        .input {
          width: 100%;
          background-color: $light-gray;
        }
      }
    }
  }
}
